@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

html,
body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #DFEAFC;
}

@keyframes slideDown {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-down {
  animation: slideDown 0.3s ease-in-out forwards;
}

.game-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1.5rem;
  gap: 6px;
  overflow: auto;
  border-radius: 6px;
  background-color: #c6daf4;
}

.height-library-list {
  height: calc(420px - 3rem);
}

.game-list li {
  flex: 1 0 calc(16.666% - 1.5rem); /* 1/6 for large screens */
  max-width: calc(16.666% - 1.5rem); /* 1/6 for large screens */
  text-align: center;
  box-sizing: border-box;
}

@media screen and (max-width: 1200px) {
  .game-list li {
    flex: 1 0 calc(25% - 1.5rem); /* 1/4 for medium screens */
    max-width: calc(25% - 1.5rem); /* 1/4 for medium screens */
  }
}

@media screen and (max-width: 768px) {
  .game-list li {
    flex: 1 0 calc(50% - 1.5rem); /* 1/2 for small screens */
    max-width: calc(50% - 1.5rem); /* 1/2 for small screens */
  }
}
